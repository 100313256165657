import React from "react"
import AdvancePlan from "../new-pricing/ad-plan2"
import Plans from "../new-pricing/plans"
export default function Plan({registrationDetails, referralToken = "", page}) {
    return (
        <div className="newlanding__plan new-pricing__hero">
            <div className="t1 mx-auto">
                Our pricing plans suitable for every business
            </div>
            <Plans nsip={true} registrationDetails={registrationDetails} refToken={referralToken} page={page} />
            <AdvancePlan page={page} />
        </div>
    )
}
