import React from "react"

import PartnerImage1 from "../../images/partners/p1.png"
import PartnerImage2 from "../../images/partners/p2.png"
import PartnerImage3 from "../../images/partners/p3.png"

import PartnerImage4 from "../../images/partners/p4.png"
import PartnerImage5 from "../../images/partners/p5.png"
import PartnerImage6 from "../../images/partners/p6.png"

export default function Partners() {
  return (
    <section className="partners bg-white">
      <div className="container">
        <div className="partners__inner">
          <div className="slide1">
            <div className="partners__logo">
              <img src={PartnerImage1} alt="partner" />
            </div>
            <div className="partners__logo enlager-partner">
              <img src={PartnerImage2} alt="partner" />
            </div>
            <div className="partners__logo">
              <img src={PartnerImage3} alt="partner" />
            </div>
          </div>
          <div className="slide2">
            <div className="partners__logo">
              <img
                src={PartnerImage4}
                alt="partner"
                className="partners__logo--fixed-size"
              />
            </div>
            <div className="partners__logo" id="partner-ayw">
              <img
                src={PartnerImage5}
                alt="partner"
                className="partners__logo--fixed-size"
              />
            </div>
            <div className="partners__logo" id="partner-r">
              <img
                src={PartnerImage6}
                alt="partner"
                className="partners__logo--fixed-size"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
