import React from "react"
import Partners from "../v2/partners"

export default function Trusts2() {
    return (
        <section className="partners">
            <div className="some-data">
            <span className="bold hidden sm:block">
              Join the 1K+ Australian businesses already e-invoicing with LUCA Plus
            </span>
                <span className="bold block sm:hidden px-5 mb-10">
                 1,000+ small businesses in Australia have already signed up including
            </span>
            </div>
            <div className="partner">
                <div className="container">
                    <div className="px-14 sm:px-20">
                        <Partners/>
                    </div>
                </div>
            </div>
        </section>
    )
}
