import React from "react"
import videoImage from "../../images/new-landing/video.png"

export default function Why({toggleVideoModal, getCode}) {
    const gotoTop = () => {
        getCode();
        try {
            document.querySelector('.landing-page-hero').scrollIntoView({
                behavior: 'smooth',
                top: 0,
                left: 0,
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }

    return (
        <div className="why">
            <div className="container flex flex-col md:flex-row justify-around items-center">
                <div className="left">
                    <div className="fvideo cursor-pointer mx-auto  w-11/12 xl:w-full" onClick={toggleVideoModal}>
                        <div className="fvideo__play"/>
                        <img src={videoImage} alt="video"/>
                    </div>
                </div>
                <div className="text-center mt-0 sm:mt-8 md:mt-0 sm:text-left sm:ml-16 xl:ml-0">
                    <div className="text-xl sm:text-4xl mt-6 sm:mt-0 text-neutral-1  font-bold">
                        Why sign up to LUCA Plus?
                    </div>
                    <div
                        className="text-neutral-3
          font-sans leading-6 desc3 mt-2 w-10/12 sm:w-auto mx-auto"
                    >
                        LUCA Plus is a game changing e-invoicing cloud-based platform for SME’s, Bookkeepers,
                        Accountants and Auditors.<br /><br />

                        Australian-based LUCA Plus’ e-invoicing platform, enables users to
                        automatically populate invoices directly into their customers and
                        suppliers accounting software, along with the ability to also receive
                        e-invoices in return from their suppliers. <br /><br />

                        So start e-invoicing, generate your rewards code below.
                    </div>

                    <div className="sm:mt-8 btn-code">
                        <a
                            onClick={gotoTop}
                            className="bg-mind flex text-white rounded text-base focus:outline-none cursor-pointer hover:bg-mint2"
                        >
                            Generate Code
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}