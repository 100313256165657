import React, { useState, useEffect } from "react"
import Axios from "axios"
import {generateNSIPPromoCode, getAllUrlParams} from "../../utils"
import classNames from "classnames";
import {Link} from "gatsby";
import Logo from "../../images/logo.svg";
const inputCls =
  "form-input w-full py-2 px-3  text-black  focus:outline-none focus:shadow-outline placeholder-gray-6"
export default function Hero({setToggle, toggle, toggleVideoModal, promoCode, toggleContactModal, setRegistrationData, navItemClick}) {
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [promo, setPromo] = useState("")

  const [firstnameCls, setfirstnameCls] = useState("")
  const [lastnameCls, setLastnameCls] = useState("")
  const [usernameCls, setUsernameCls] = useState("")
  const [passwordCls, setPasswordCls] = useState("")
  const [passwordConfirmCls, setPasswordConfirmCls] = useState("")
  // const [promoCls, setPromoCls] = useState("")
  useEffect(() => {
    if (typeof window !== "undefined") {
      setPromo(getAllUrlParams().promo_code)
      console.log(getAllUrlParams().email)
      setUsername(getAllUrlParams().email)
    }
  }, [])

  useEffect(() => {
    if(promoCode) {
      setPromo(promoCode)
    }
  },[promoCode])

  const handleSubmit = event => {
    event.preventDefault()
    setfirstnameCls("")
    setLastnameCls("")
    setUsernameCls("")
    setPasswordCls("")
    setPasswordConfirmCls("")
    // setPromoCls("")

    if (!firstname) {
      alert("Please enter  your First name")
      setfirstnameCls("border-red")
      return
    }
    if (!lastname) {
      alert("Please enter  your  Last name")
      setLastnameCls("border-red")
      return
    }

    if (!username) {
      alert("Please enter yourEmail")
      setUsernameCls("border-red")
      return
    }

    setRegistrationData({firstname, lastname, username, promo })
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }

  }

  const gotoPricing = () => {
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }

  const toggleCls = classNames('navbar-toggler',
      { 'collapsed': !toggle }
  )
  const mobileMenuWrapperCls = classNames(
      { 'show': toggle }
  )

  const handleToggleMenu = (e) => {
    setToggle(!toggle)
  }

  const generateCode = (e) => {
    e.preventDefault();
    setPromo(generateNSIPPromoCode());
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setPromo(getAllUrlParams().promo_code)
      console.log(getAllUrlParams().email)
      setUsername(getAllUrlParams().email)
    }
  }, [])

  return (
    <>
      <header className="site-header site-header--nofix">
        <div className="container">
        <div className="header-inner">
          <nav className="site-nav">
            <div className="logo ml-8">
              <Link to="/" ><img src={Logo} alt="luca logo" /></Link>
            </div>

            <div id="site-menu-wrapper">
              <ul className="site-menu">
                <li onClick={() => navItemClick("Terms & Conditions")}><a href="https://go.luca.plus/nsip-terms-and-conditions" target="__blank" className="hover:text-gray-26 mx-5">Terms & Conditions</a></li>
                <li onClick={() => navItemClick("Contact Us")}><a onClick={toggleContactModal} className="hover:text-gray-26 ml-5 cursor-pointer">Contact Us</a></li>
              </ul>
            </div>
            <div className={toggleCls} onClick={handleToggleMenu}>
              <span className="menu_toggle" >
                <span className="hamburger">
                  <span/>
                  <span/>
                  <span/>
                </span>
                <span className="hamburger-cross">
                  <span/>
                  <span/>
                </span>
              </span>
            </div>
          </nav>
        </div>
      </div>
        <div id="mobile-menu-wrapper" className={mobileMenuWrapperCls}>
          <ul className="mobile-menu">
            <li onClick={() => navItemClick("Terms & Conditions")}><a href="https://go.luca.plus/nsip-terms-and-conditions" target="__blank" className="hover:text-gray-26 mx-10">Terms & Conditions</a></li>
            <li onClick={() => navItemClick("Contact Us")}><a onClick={toggleContactModal} className="hover:text-gray-26 mx-10">Contact Us</a></li>
          </ul>
        </div>
      </header>
      <a name="create_account" id="create_account"></a>
      <div className="landing-page-hero flex items-center relative">
        <div className="landing-page-hero__overlay"></div>
        <div className="landing-page-hero__left_image"></div>
        <div className="speech-bubble font-semibold text-mind text-left hidden sm:block">
          Start <br /> e-invoicing today, earn up to $2,000*
        </div>
        <div className="container z-20 inner">
          <div className="flex justify-end sm:justify-content-center register-form-wrap">
            <form
              className="register-form text-white z-30  "
              // onSubmit={}
            >
              <div className="card">
                <div className="form-content">
                  <div>
                    <h3 className="text-brand pt-10 text-xl font-bold leading-7 mx-5">
                      Earn up to $2000 by connecting your customers and suppliers
                    </h3>
                    <div className="text-gray-19 sub-header mt-2 text-sm">
                      Sign Up for FREE for 30 days
                    </div>
                  </div>
                  <div className="lhero-form ">
                  <div className="mt-4">
                    <label className="block " htmlFor="firstName">
                      <input
                        className={`${inputCls} ${firstnameCls}`}
                        id="firstName"
                        type="text"
                        placeholder="First name"
                        onChange={event => {
                          setFirstname(event.target.value)
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="block " htmlFor="lastname">
                      <input
                        className={`${inputCls} ${lastnameCls}`}
                        id="lastname"
                        type="text"
                        placeholder="Last name"
                        onChange={event => {
                          setLastname(event.target.value)
                        }}
                      />
                    </label>
                  </div>

                  <div className="mt-4">
                    <label className="block " htmlFor="email">
                      <input
                        className={`${inputCls} ${usernameCls}`}
                        id="email"
                        type="text"
                        placeholder="E-mail"
                        value={username || ""}
                        onChange={event => {
                          setUsername(event.target.value)
                        }}
                      />
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="flex " htmlFor="promo">
                      <input
                        className={`${inputCls} ${lastnameCls} code`}
                        id="promo"
                        type="text"
                        placeholder="Promo code"
                        value={promo || ""}
                        onChange={event => {
                          setPromo(event.target.value)
                        }}
                      />
                      <button className="generate-code-btn text-neutral-3 focus:outline-none" onClick={generateCode}>
                        Generate Code
                      </button>
                    </label>
                  </div>
                  <div className="mt-6 sm:mt-6 pb-6 sm:pb-8 cursor-pointer">
                    <input
                      onClick={handleSubmit}
                      value="Select Plan"
                      className="bg-mind rounded w-full  text-base register-btn text-center focus:outline-none"
                    />
                  </div>
                </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
