import React from "react"
import {Carousel} from 'react-responsive-carousel'
import {STORIES2} from "../../data/stories"

export default function Fans({myob = false}) {
  let cards1 = [];
  let cards2 = [];
  for (let i = 0; i < STORIES2.length; i = i + 2) {
    cards1.push(<div className="flex justify-content-center px-0 sm:px-8" key={i}>
      <div
          className="container flex flex-col justify-center bg-white card card-border overflow-hidden mx-0 sm:mx-3 z-20 relative mb-10 sm:mb-20 px-0 sm:px-5 md:px-10"
      >
        <div className="paragraph text-neutral-3  text-left">
          {STORIES2[i].paragrah}
        </div>
        <div className="flex sm:mt-8 sm:items-baseline items-center">
          <div className={`image mr-3`}>
            <img
                src={STORIES2[i].image}
                alt={STORIES2[i].name}
                className="rounded-full object-cover object-top"
            />
          </div>
          <div className="flex justify-center text-left flex-col">
            <div className="text-base font-medium title1">
              {STORIES2[i].name}
            </div>
            <div className="flex text-neutral-3 text-sm title">
              {STORIES2[i].title}
            </div>
          </div>
        </div>
      </div>
      <div
          className="container flex flex-col justify-center bg-white card card-border overflow-hidden mx-0 sm:mx-3 z-20 relative mb-10 sm:mb-20 px-0 sm:px-5 md:px-10"
      >
        <div className="paragraph text-neutral-3  text-left">
          {STORIES2[i + 1].paragrah}
        </div>
        <div className="flex sm:mt-8 sm:items-baseline items-center">
          <div className="image mr-3">
            <img
                src={STORIES2[i + 1].image}
                alt={STORIES2[i + 1].name}
                className="rounded-full object-cover object-top"
            />
          </div>
          <div className="flex justify-center text-left flex-col">
            <div className="text-base font-medium title1">
              {STORIES2[i + 1].name}
            </div>
            <div className="text-neutral-3 text-sm title">
              {STORIES2[i + 1].title}
            </div>
          </div>
        </div>
      </div>
    </div>)
  }

  for (let i = 0; i < STORIES2.length; i++) {
    cards2.push(<div className="flex justify-content-center px-0 sm:px-8" key={i}>
      <div
          className="container flex flex-col justify-center bg-white card card-border overflow-hidden  mx-0 sm:mx-3 z-20 relative mb-10 sm:mb-20 px-0 sm:px-5 md:px-10"
      >
        <div className="paragraph text-neutral-3 text-left">
          {STORIES2[i].paragrah}
        </div>
        <div className="flex sm:mt-8 sm:items-baseline items-center">
          <div className={`image mr-3`}>
            <img
                src={STORIES2[i].image}
                alt={STORIES2[i].name}
                className="rounded-full object-cover object-top"
            />
          </div>
          <div className="flex justify-center text-left flex-col">
            <div className="text-base font-medium">
              {STORIES2[i].name}
            </div>
            <div className="flex text-neutral-3 text-sm title">
              {STORIES2[i].title}
            </div>
          </div>
        </div>
      </div>
    </div>)
  }

  return (
      <div className="fans sm:mt-0 mt-8">
        <div className="t1 mx-auto sm:mb-0 mb-5">
        {myob ?"Take it from real users experiencing savings with MYOB and LUCA Plus":"Take it from real users experiencing savings with Quickbooks and LUCA Plus"}
        </div>
        <div className="card-wrap pt-12">
          <Carousel className="hidden lg:block md:block">
            {cards1}
          </Carousel>
          <Carousel className="sm:hidden">
            {cards2}
          </Carousel>
        </div>
      </div>
  )
}
