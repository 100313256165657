import React, {useEffect, useState} from "react"
import queryString from "query-string";
import ReactGA from "react-ga";

import SEO from "../components/seo"
import Footer from "../components/footer"
import Plans from "../components/nsip/plans"

import Trusts2 from "../components/nsip/trusts2"
import "../scss/style.scss"

import Hero from "../components/nsip/hero"
import Accolades from "../components/nsip/accolades"
import Why from "../components/nsip/why"
import Fans from "../components/new-landing/fans"
import Demo from "../components/nsip/demo"
import Modali, { useModali } from "../components/modal"
import Partner from "../components/video/partners";
import Users from "../components/nsip/users";
import {generateNSIPPromoCode} from "../utils";
import seoImage from "../images/nsip/seo-bg.jpg"

export default function NSIP({location}) {
    const [videoModal, toggleVideoModal] = useModali()
    const [videoModal1, toggleVideoModal1] = useModali()
    const [contactModal, toggleContactModal] = useModali()
    const [registrationDetails, setRegistrationDetails] = useState(null);
    const [toggle, setToggle] = useState(false)
    const [promo, setPromo] = useState("")
    const [referralToken, setReferralToken] = useState("")

    useEffect(() => {
        const urlState = queryString.parse(location.search)
        if(urlState.ref_token){
            setReferralToken(urlState.ref_token);
        }
    },[])

    const getCode = () => {
        setPromo(generateNSIPPromoCode());
    }

    const setRegistrationData = (data) => {
        setRegistrationDetails(data);
    }
    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of NSIP Page`
        });
    }
    return (
        <div className="h-full font-robo nsip-page">
            <SEO title="E-invoicing Sign Up Incentive Program: Earn upto $2000" einvoice={true} description="Grab this e-invoicing incentive: Earn up to $2,000 by connecting your clients and suppliers into LUCA+ e-invoicing platform." image={seoImage} 
            keywords="e-invoicing incentive" />
            <Hero toggle={toggle} setToggle={setToggle} toggleVideoModal={toggleVideoModal}
                  toggleContactModal={toggleContactModal} promoCode={promo} setRegistrationData={setRegistrationData}
                  navItemClick={navItemClick}/>
            <section className="bg-white2 bg-white sm:hidden pad-block"></section>
            <Partner/>
            <Users toggleVideoModal1={toggleVideoModal1}/>
            <Plans registrationDetails={registrationDetails} referralToken={referralToken} page="NSIP page" />
            <Why toggleVideoModal={toggleVideoModal} getCode={getCode} />
            <Fans />
            <Demo getCode={getCode} />
            <Trusts2 />
            <Accolades />
            <div className="line" />
            <Footer simple={true} nsip={true} />
            <Modali.Modal {...videoModal}>
                <div className="hero-video">
                    <iframe
                        src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
                        title="Introduction to LUCA+"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </Modali.Modal>
            <Modali.Modal {...videoModal1}>
                <div className="hero-video">
                    <iframe
                        src="https://go.luca.plus/nsip-rewards-incentive-invite"
                        title="Introduction to LUCA+"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </Modali.Modal>
            <Modali.Modal {...contactModal} hubspot={true}>
                <div className="hero-hubspot">
                    <iframe
                        src="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
                        title="Introduction to LUCA Plus"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </Modali.Modal>
        </div>
    )
}
