import React, {useState} from "react"
import userPlus from "../../images/nsip/user-plus.svg"
import userCheck from "../../images/nsip/user-check.svg"
import newUsersS1 from "../../images/nsip/new-users-s1.svg"
import newUsersS2 from "../../images/nsip/new-users-s2.svg"
import newUsersS3 from "../../images/nsip/new-users-s3.svg"
import newUsersS4 from "../../images/nsip/new-users-s4.svg"
import existingUserS1 from "../../images/nsip/existing-users-s1.svg"
import existingUserS2 from "../../images/nsip/existing-users-s2.svg"
import existingUserS3 from "../../images/nsip/existing-users-s3.svg"
import existingUserS4 from "../../images/nsip/existing-users-s4.svg"
import arrowRight from "../../images/nsip/arrow-right.svg"
import {navigate} from "../../../.cache/gatsby-browser-entry";

export default function Users({toggleVideoModal1}) {
  const [newUser, toggleNewUser] = useState(false)
  const goToPricing = () => {
      try {
          document.querySelector('.new-pricing__hero').scrollIntoView({
              behavior: 'smooth',
              top: 0,
              left: 0,
          });
      } catch (error) {
          window.scrollTo(0, 0);
      }
  }
  return (
    <section className="users container">
      <div className="header ">
         <div className="heading text-neutral-1 mx-auto">
            Earn up to $2,000 by connecting your customers and suppliers to enjoy the benefits of e-invoicing with LUCA Plus
         </div>
         <div className="text-base  text text-neutral-3 mx-auto">
            With the financial challenges brought on by COVID-19, many Australian businesses are needing that extra financial
            support. To help, here at LUCA Plus we developed a <span className="text-gray-17 font-bold"> New Sign Up Incentive Program (NSIP) </span> where existing and new
            subscribers can earn together, whilst enjoying the many financial benefits <br /> e-invoicing with LUCA Plus brings.
         </div>
          <div className="mt-8 flex justify-center items-center flex-col">
              <div className="flex">
                <div className={`${newUser && 'deactive'} tab flex justify-center `} onClick={() => toggleNewUser(!newUser)}>
                   <img src={userPlus} alt="user-plus" className="mb-3" />
                  <div className="text ml-2">
                      New Users
                  </div>
                </div>
                <div className={`${!newUser && 'deactive'} tab flex justify-center ml-3`} onClick={() => toggleNewUser(!newUser)}>
                  <img src={userCheck} alt="user-plus" className="mb-3" />
                  <div className="text ml-2">
                    Existing Users
                  </div>
                </div>
              </div>
              {!newUser ? <div className="flex container items-center sm:justify-between flex-col sm:flex-row mt-12">
                <div className="box1 flex flex-col items-start mt-2">
                  <img src={newUsersS1} alt="user-plus" className="mb-3 cursor-pointer" onClick={goToPricing} />
                  <div className="text-brand my-3">
                      STEP 1
                  </div>
                  <div className="box-content text-neutral-3 text-sm text-left">
                      Select your plan below, sign up with a valid ABN and enter promocode #NSIP20 on sign up
                  </div>
                  <a className="flex text-mind items-center my-2 cursor-pointer" onClick={goToPricing}>
                        Select Plan
                      <img src={arrowRight} alt="user-plus" className="ml-1" />
                  </a>
                </div>
                <div className="box1 flex flex-col items-start mt-2">
                  <img src={newUsersS2} alt="user-plus" className="mb-3 cursor-pointer" onClick={() => navigate("https://app.lucaplus.com")} />
                  <div className="text-brand my-3">
                      STEP 2
                  </div>
                  <div className="box-content text-neutral-3 text-sm text-left">
                      Within your LUCA Plus App, apply your nominated Pay ID/Business Bank Account details
                  </div>
                  <a className="flex text-mind items-center my-3" href="https://app.lucaplus.com">
                      Log In
                      <img src={arrowRight} alt="user-plus" className="ml-1" />
                  </a>
              </div>
                <div className="box2 flex flex-col items-start mt-2">
                  <img src={newUsersS3} alt="user-plus" className="mb-3 cursor-pointer" onClick={() => navigate("https://app.lucaplus.com")} />
                  <div className="text-brand my-3">
                      STEP 3
                  </div>
                  <div className="box-content text-neutral-3 text-sm text-left">
                      You are now eligible to receive $20 into your nominated account at the end of the month
                  </div>
                  <a className="flex text-mind items-center my-3" href="https://app.lucaplus.com">
                      Get Started
                      <img src={arrowRight} alt="user-plus" className="ml-1" />
                  </a>
              </div>
                <div className="box4 flex flex-col items-start mt-2">
                    <img src={newUsersS4} alt="user-plus" className="mb-3 cursor-pointer" onClick={toggleVideoModal1} />
                    <div className="text-brand my-3">
                        STEP 4
                    </div>
                    <div className="box-content text-neutral-3 text-sm text-left">
                        Watch this short video to learn how to earn up to $2000
                    </div>
                    <div className="flex text-mind items-center my-3 cursor-pointer" onClick={toggleVideoModal1}>
                        Watch Now
                        <img src={arrowRight} alt="user-plus" className="ml-1" />
                    </div>
                  </div>
              </div>
              : <div className="flex container items-center sm:justify-between flex-col sm:flex-row mt-12">
                      <div className="box1 flex flex-col items-start mt-2">
                          <img src={existingUserS1} alt="user-plus" className="mb-3 cursor-pointer" onClick={() => navigate("https://app.lucaplus.com")} />
                          <div className="text-brand my-3">
                              STEP 1
                          </div>
                          <div className="box-content text-neutral-3 text-sm text-left">
                              Login to your LUCA Plus Dashboard and click on the NETWORK tab
                          </div>
                          <a className="flex text-mind items-center my-3" href="https://app.lucaplus.com">
                              Log In
                              <img src={arrowRight} alt="user-plus" className="ml-1" />
                          </a>
                      </div>
                      <div className="box1 flex flex-col items-start mt-2">
                          <img src={existingUserS2} alt="user-plus" className="mb-3 cursor-pointer" onClick={() => navigate("https://app.lucaplus.com")} />
                          <div className="text-brand my-3">
                              STEP 2
                          </div>
                          <div className="box-content text-neutral-3 text-sm text-left">
                              Within your LUCA Plus App, start inviting your network by clicking on the “Invite” button
                          </div>
                          <a className="flex text-mind items-center my-3" href="https://app.lucaplus.com">
                              Log In
                              <img src={arrowRight} alt="user-plus" className="ml-1" />
                          </a>
                      </div>
                      <div className="box2 flex flex-col items-start mt-2">
                          <img src={existingUserS3} alt="user-plus" className="mb-3 cursor-pointer" onClick={() => navigate("https://app.lucaplus.com")}/>
                          <div className="text-brand my-3">
                              STEP 3
                          </div>
                          <div className="box-content text-neutral-3 text-sm text-left">
                              Add your nominated Pay ID/Bank Account details under the Organisation tab
                          </div>
                          <a className="flex text-mind items-center my-3 " href="https://app.lucaplus.com">
                              Get Started
                              <img src={arrowRight} alt="user-plus" className="ml-1" />
                          </a>
                      </div>
                      <div className="box4 flex flex-col items-start mt-2">
                          <img src={existingUserS4} alt="user-plus" className="mb-3 cursor-pointer" onClick={toggleVideoModal1}/>
                          <div className="text-brand my-3">
                              STEP 4
                          </div>
                          <div className="box-content text-neutral-3 text-sm text-left">
                              Watch this short video to learn how to earn up to $2000
                          </div>
                          <div className="flex text-mind items-center my-3 cursor-pointer" onClick={toggleVideoModal1}>
                              Watch Now
                              <img src={arrowRight} alt="user-plus" className="ml-1" />
                          </div>
                      </div>
                  </div>}
          </div>
      </div>
    </section>
  )
}
