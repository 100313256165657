import React from "react";
import c1Image from "../images/stories/cs1.jpg"
import c2Image from "../images/stories/cs2.jpg"
import c3Image from "../images/stories/cs3.jpg"

import c11Image from "../images/stories/cs11.jpg"
import c22Image from "../images/stories/cs22.jpg"
import c33Image from "../images/stories/cs33.jpg"
import c44Image from "../images/stories/cs4.jpg"



export const STORIES = [
  {
    content: "As a self employed electrician, one of my biggest challenges is managing cashflow. Some of my customers don’t pay me on time which puts a strain on my business and potentially my ability to do the next job.", name: "Ron Carter, Geelong", image: c1Image
  },
  { content: "My husband and I run a local café in Southbank.We get fresh produce delivered everyday from our suppliers, and at the end of an exhaustive week I estimate that I spend at least half a day entering invoices into Xero.", name: "Kate Wright, Southbank", image: c2Image },
  { content: "Financial year end is the busiest time of the year for me.I would spend hours going through my clients books, and when they don’t balance it could take me days to find the discrepancies.", name: "Victor from INP Accounting", image: c3Image },

]


export const STORIES2 = [
  {
    content: "Angus saved over X hours of time switching to e - invoicing.", name: "Angus Muffatti", image: c33Image, paragrah: "“It’s amazing how LUCA Plus e-invoicing system can help out Fintech start-ups like us. It makes it so easy for us to manage bills sent from freelancers and contractors. Bookkeeping can be done in seconds.’’", title: " CEO at Agtuary"
  },
  {
    content: "Bob saved over X hours of time switching to e - invoicing.", name: "Shelley Hon", image: c22Image, paragrah: "‘’We have built very good relationship with our suppliers because they get paid faster due to LUCA Plus.  It also reduces bookkeeping errors for our business and saves us time processing invoices and bills.’’", title: "CEO/Founder at Odele Cosmetics"
  },

  {
    content: "Bob saved over X hours of time switching to e-invoicing.", name: "Steve Shi", image: c11Image, paragrah: <div>“Our construction business engages with many project companies, tradies and subcontractors. LUCA Plus certainly simplifies the whole invoicing and billing management systems for us. <span className='sm:contents hidden'>We can save a lot of time to focus more on developing new businesses.</span>”</div>, title: " Senior Project Manager at Cornerstone Building & Construction "
  },
  {
    content: "Bob saved over X hours of time switching to e-invoicing.", name: "Yanina", image: c44Image, paragrah: "“I am really bad at managing my invoices and bills for my freelancer business. But LUCA Plus really helps me to focus on growing my business and saves me so much time dealing with painful bookkeeping. I really love it.”", title: "Artist & Freelancer"
  },
]

export const STORIES3 = [
  {
    content: "Bob saved over X hours of time switching to e-invoicing.", name: "Steve Shi,  Senior Project Manager ", image: c11Image, paragrah: "‘’Our construction business engages with many project companies, tradies and subcontractors. Integrating my existing QuickBooks account with LUCA Plus, certainly simplifies the whole invoicing and billing management systems for us. We can save a lot of time to focus more on developing new businesses.’’", title: "Cornerstone Building & Construction "
  },
  {
    content: "Bob saved over X hours of time switching to e - invoicing.", name: "Shelley Hon, CEO/Founder ", image: c22Image, paragrah: "‘’We have built great relationships with our suppliers because they get paid faster. I recommend integrating QuickBooks with LUCA Plus, as it reduces bookkeeping errors for our business and saves us time processing invoices and bills.’’", title: "Odele Cosmetics"
  }
]
