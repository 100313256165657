import React from "react"

export default function Demo({getCode}) {
    const gotoTop = () => {
        getCode();
        try {
            document.querySelector('.landing-page-hero').scrollIntoView({
                behavior: 'smooth',
                top: 0,
                left: 0,
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }
    return (
        <section className="demo bg-gray-30 flex items-center">
            <div className="container sm:flex sm:flex-row justify-between items-center">
                <div className="left flex items-center ml-8">
                    <h3 className="mx-auto text-2xl font-bold text-left">
                        Get rewarded and earn together, whilst enjoying the many financial benefits e-invoicing with LUCA Plus brings
                    </h3>
                </div>
                <div className="right text-left sm:mt-0 mt-8 ml-8 sm:ml-20 mr-0 sm:mr-8 leading-6 text-gray-20">
                    <a
                        onClick={gotoTop}
                        className="bg-mind rounded leading-7 rounded  focus:outline-none text-white px-10 py-3 capitalize create-btn"
                    >
                        Generate Code
                    </a>
                </div>
            </div>
        </section>
    )
}
